import { GLAttachment } from "../../Shared/components/Attachments/Attachments.types";
import { NotificationType } from "../../Shared/contexts/LoggingContext/LoggingContext";
import { ApiParsingUtilities } from "../../Shared/utilities/ApiParsingUtilities";
import { GetStatusString } from "../../Shared/utilities/IStatusCodes";
import { GetClassification } from "../../Shared/utilities/PostingTypeToString";
import { regStoreAttachmentsToGLAttachments } from "./LegacyAttachmentToIAttachment";

export interface AISearchResult extends GLDashboardRowFromApi {
  searchInfos: GLDashboardRowFromApi[];
  totalCount: number;
  nextPageIndex: number;
  totalPages: number;
  uiLimit: number;
}

export interface GLDashboardRowFromApi extends ObjectContainingLegacyAttachments {
  access: number;
  actionEnabled: boolean;
  actualReviewer: string;
  author: string;
  bgCode: string;
  companyCode: number;
  dateModified: string;
  description: string;
  docNumber: number;
  dueDate: string | null;
  entryType: number;
  fiscalPeriod: number;
  fiscalYear: number;
  isBPO: boolean;
  jeId: number;
  jeName: string;
  jeStatus: number;
  jeType: string;
  mark: string;
  poster: string;
  postingType: number;
  reasonCode: string;
  reasonDescription: string;
  refGuid: string;
  refNum: string;
  reverseJENum: number;
  reviewedDate: string | null;
  reviewer: string;
  reviewerAction: string;
  reviewerComment: string;
  siginificance: string;
  version: string;
  wfGuid: null | string;
  additionalPosters: string;
  isTenant: number;
  isPostedByJem: number;
  rowWeightage: number;
  opsDetailsName: string | null;
  jeChecklistName: string | null;
  isAdhocOrStd: string | null;
}

export interface ObjectContainingLegacyAttachments {
  attachments: LegacyAttachments | null;
}

export interface LegacyAttachments {
  corpStoreAttachments: CorpStoreAttachmentInfo[];
  regStoreAttachments: RegStoreAttachment[];
}

export interface CorpStoreAttachmentInfo {
  fileId: number;
  originalName: string;
  corpStoreId: number | null;
}

export interface RegStoreAttachment {
  blobName: string | null;
  fileId: number;
  fileSize: number | null;
  fileStatus: number | null;
  originalName: string | null;
  regStoreId: number | null;
  url: string | null;
  uploadedDate: string | null;
  isRescindEvidence: boolean | null;
  region?: string | null;
}

export interface RegStoreAttachmentForUpload {
  BlobName: string | null;
  FileId: number;
  FileSize: number | null;
  FileStatus: number | null;
  OriginalName: string | null;
  RegStoreId: number | null;
  URL: string | null;
  UploadedDate: string | null;
  IsRescindEvidence: boolean | null;
}

export interface SanitizedDraftDashboardRow {
  idForUI: string;
  id: string;
  jeId: string;
  draftName: string;
  jeRefNo: string;
  refGuid: string;
  companyCode: string[];
  description: string;
  status: string;
  statusCode: number;
  reviewer: string[];
  reoccuring: string;
  attachmentStatus: string;
  postingPeriod: string;
  fiscalPeriod: string;
  fiscalYear: string;
  format: string;
  modifiedOn: string;
  postingType: string;
  lastComments: string;
  attachments: string;
  isBPOEntry: string;
  checklistRefguid: string;
  templateid: string;
  poster: string;
  additionalPosters: string[];
  rowWeightage: number;
  isDraft: boolean;
  isPosted: boolean;
  processingStatus?: string;
  procStatType: NotificationType;
  allowedActions?: string;
}

export interface SanitizedDashboardRow {
  idForUI: string;
  jeId: string;
  docNumber: string;
  jeName: string;
  reverseJENum: string;
  companyCode: string[];
  siginificance: string;
  jeType: string;
  status: string;
  statusCode: number;
  description: string;
  author: string;
  poster: string;
  additionalPosters: string[];
  reviewer: string[];
  postingType: string;
  fiscalYear: string;
  fiscalPeriod: string;
  format: string;
  dateModified: string;
  dueDate: string;
  reasonCode: string;
  isBPO: string;
  reasonDescription: string;
  reviewerAction: string;
  reviewerComment: string;
  wfGuid: string;
  refGuid: string;
  access: number;
  mark: string;
  refNum: string;
  version: string;
  entryType: string;
  bgCode: string;
  actualReviewer: string;
  reviewedDate: string;
  actionEnabled: string;
  attachments: GLAttachment[];
  attachmentCount: number;
  isTenant: string;
  isPostedByJem: string;
  processingStatus: string;
  procStatType: NotificationType;
  rowWeightage: number;
  isDraft: boolean;
  opsDetailsName: string;
  jeChecklistName: string;
  isAdhocOrStd: string;
  isPosted: boolean;
}
export interface SanitizedPreReviewDashboardRow extends SanitizedDraftDashboardRow {
  preReviewer: string[];
  statusName: string;
}
export interface SanitizedAIDashboardRow extends SanitizedDashboardRow {
  totalCount?: number;
  totalPages?: number;
  nextPageIndex?: number;
  uiLimit?: number;
}
export type GLDashboardRow = SanitizedDraftDashboardRow &
  SanitizedDashboardRow &
  SanitizedPreReviewDashboardRow &
  SanitizedAIDashboardRow;

export function sanitizeDashboardRow(row: GLDashboardRowFromApi): SanitizedDashboardRow {
  const attachmentCount =
    (row.attachments?.corpStoreAttachments.length || 0) + (row.attachments?.regStoreAttachments.length || 0);
  const docNumber = ApiParsingUtilities.addDefault(row.docNumber, "");

  const newSanitizedRow: SanitizedDashboardRow = {
    idForUI: docNumber,
    access: ApiParsingUtilities.parseNumberToNumber(row.access, 0),
    actionEnabled: ApiParsingUtilities.addDefault(row.actionEnabled, ""),
    actualReviewer: ApiParsingUtilities.addDefault(row.actualReviewer, ""),
    author: ApiParsingUtilities.addDefault(row.author, ""),
    bgCode: ApiParsingUtilities.addDefault(row.bgCode, ""),
    companyCode: ApiParsingUtilities.addDefault(row.companyCode, "")
      .split(",")
      .filter((x) => x),
    dateModified: ApiParsingUtilities.parseDate(row.dateModified),
    description: ApiParsingUtilities.addDefault(row.description, ""),
    docNumber: docNumber,
    dueDate: ApiParsingUtilities.parseDate(row.dueDate),
    entryType: ApiParsingUtilities.addDefault(row.entryType, ""),
    fiscalPeriod: ApiParsingUtilities.addDefault(row.fiscalPeriod, ""),
    fiscalYear: ApiParsingUtilities.addDefault(row.fiscalYear, ""),
    format: GetClassification(row.postingType),
    isBPO: ApiParsingUtilities.addDefault(row.isBPO, ""),
    jeId: ApiParsingUtilities.addDefault(row.jeId, ""),
    jeName: ApiParsingUtilities.addDefault(row.jeName, ""),
    status: GetStatusString(row.jeStatus),
    statusCode: row.jeStatus,
    jeType: ApiParsingUtilities.addDefault(row.jeType, ""),
    mark: ApiParsingUtilities.addDefault(row.mark, ""),
    poster: ApiParsingUtilities.addDefault(row.poster, ""),
    postingType: ApiParsingUtilities.addDefault(row.postingType, ""),
    reasonCode: ApiParsingUtilities.addDefault(row.reasonCode, ""),
    reasonDescription: ApiParsingUtilities.addDefault(row.reasonDescription, ""),
    refGuid: ApiParsingUtilities.addDefault(row.refGuid, ""),
    refNum: ApiParsingUtilities.addDefault(row.refNum, ""),
    reverseJENum: ApiParsingUtilities.addDefault(row.reverseJENum, ""),
    reviewedDate: ApiParsingUtilities.parseDate(row.reviewedDate),
    reviewer: ApiParsingUtilities.addDefault(row.reviewer, "")
      .split(";")
      .filter((x) => x),
    reviewerAction: ApiParsingUtilities.addDefault(row.reviewerAction, ""),
    reviewerComment: ApiParsingUtilities.addDefault(row.reviewerComment, ""),
    siginificance: ApiParsingUtilities.addDefault(row.siginificance, ""),
    version: ApiParsingUtilities.addDefault(row.version, ""),
    wfGuid: ApiParsingUtilities.addDefault(row.wfGuid, ""),
    additionalPosters: ApiParsingUtilities.addDefault(row.additionalPosters, "")
      .split(";")
      .filter((x) => x),
    attachments: regStoreAttachmentsToGLAttachments(row.attachments),
    attachmentCount: attachmentCount,
    isTenant: ApiParsingUtilities.addDefault(row.isTenant, ""),
    isPostedByJem: ApiParsingUtilities.addDefault(row.isPostedByJem, ""),
    processingStatus: "",
    procStatType: "Information",
    rowWeightage: row.rowWeightage,
    isDraft: false,
    opsDetailsName: ApiParsingUtilities.addDefault(row.opsDetailsName, ""),
    jeChecklistName: ApiParsingUtilities.addDefault(row.jeChecklistName, ""),
    isAdhocOrStd: ApiParsingUtilities.addDefault(row.isAdhocOrStd, ""),
    isPosted: row.docNumber ? true : false
  };
  return newSanitizedRow;
}

export function sanitizeAISearchDashboardRow(row: AISearchResult): SanitizedAIDashboardRow[] {
  return row.searchInfos.map((info: GLDashboardRowFromApi) => {
    const attachmentCount =
      (info.attachments?.corpStoreAttachments?.length || 0) + (info.attachments?.regStoreAttachments?.length || 0);
    const docNumber = ApiParsingUtilities.addDefault(info.docNumber, "");

    const newSanitizedRow: SanitizedAIDashboardRow = {
      idForUI: docNumber,
      access: ApiParsingUtilities.parseNumberToNumber(info.access, 0),
      actionEnabled: ApiParsingUtilities.addDefault(info.actionEnabled, ""),
      actualReviewer: ApiParsingUtilities.addDefault(info.actualReviewer, ""),
      author: ApiParsingUtilities.addDefault(info.author, ""),
      bgCode: ApiParsingUtilities.addDefault(info.bgCode, ""),
      companyCode: ApiParsingUtilities.addDefault(info.companyCode, "")
        .split(",")
        .filter((x) => x),
      dateModified: ApiParsingUtilities.parseDate(info.dateModified),
      description: ApiParsingUtilities.addDefault(info.description, ""),
      docNumber: docNumber,
      dueDate: ApiParsingUtilities.parseDate(info.dueDate),
      entryType: ApiParsingUtilities.addDefault(info.entryType, ""),
      fiscalPeriod: ApiParsingUtilities.addDefault(info.fiscalPeriod, ""),
      fiscalYear: ApiParsingUtilities.addDefault(info.fiscalYear, ""),
      format: GetClassification(info.postingType),
      isBPO: ApiParsingUtilities.addDefault(info.isBPO, ""),
      jeId: ApiParsingUtilities.addDefault(info.jeId, ""),
      jeName: ApiParsingUtilities.addDefault(info.jeName, ""),
      status: GetStatusString(info.jeStatus),
      statusCode: info.jeStatus,
      jeType: ApiParsingUtilities.addDefault(info.jeType, ""),
      mark: ApiParsingUtilities.addDefault(info.mark, ""),
      poster: ApiParsingUtilities.addDefault(info.poster, ""),
      postingType: ApiParsingUtilities.addDefault(info.postingType, ""),
      reasonCode: ApiParsingUtilities.addDefault(info.reasonCode, ""),
      reasonDescription: ApiParsingUtilities.addDefault(info.reasonDescription, ""),
      refGuid: ApiParsingUtilities.addDefault(info.refGuid, ""),
      refNum: ApiParsingUtilities.addDefault(info.refNum, ""),
      reverseJENum: ApiParsingUtilities.addDefault(info.reverseJENum, ""),
      reviewedDate: ApiParsingUtilities.parseDate(info.reviewedDate),
      reviewer: ApiParsingUtilities.addDefault(info.reviewer, "")
        .split(";")
        .filter((x) => x),
      reviewerAction: ApiParsingUtilities.addDefault(info.reviewerAction, ""),
      reviewerComment: ApiParsingUtilities.addDefault(info.reviewerComment, ""),
      siginificance: ApiParsingUtilities.addDefault(info.siginificance, ""),
      version: ApiParsingUtilities.addDefault(info.version, ""),
      wfGuid: ApiParsingUtilities.addDefault(info.wfGuid, ""),
      additionalPosters: ApiParsingUtilities.addDefault(info.additionalPosters, "")
        .split(";")
        .filter((x) => x),
      attachments: regStoreAttachmentsToGLAttachments(info.attachments),
      attachmentCount: attachmentCount,
      isTenant: ApiParsingUtilities.addDefault(info.isTenant, ""),
      isPostedByJem: ApiParsingUtilities.addDefault(info.isPostedByJem, ""),
      processingStatus: "",
      procStatType: "Information",
      rowWeightage: info.rowWeightage,
      isDraft: false,
      opsDetailsName: ApiParsingUtilities.addDefault(info.opsDetailsName, ""),
      jeChecklistName: ApiParsingUtilities.addDefault(info.jeChecklistName, ""),
      isAdhocOrStd: ApiParsingUtilities.addDefault(info.isAdhocOrStd, ""),
      isPosted: info.docNumber ? true : false,
      totalCount: row.totalCount,
      totalPages: row.totalPages,
      nextPageIndex: row.nextPageIndex,
      uiLimit: row.uiLimit
    };
    return newSanitizedRow;
  });
}
