import { css } from "@emotion/css";
import { Selection, SelectionMode, Text } from "@fluentui/react";
import { OrderedMap } from "immutable";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
// import { GLDashboardActions } from "./GLDashboard.Actions";
import {
  // DashboardListActions,
  DashboardFilter,
  DashboardGrid,
  DashboardListActionsRef,
  exportToExcel,
  FilterSchema,
  FilterState,
  IDashboardGridProps,
  IDashboardGridRef,
  IDashboardListActionsState,
  IIndexedTile,
  ActionsManager,
  ActionsProvider,
  GLDashboardRow,
  IActionsManagerRef,
  JemConfiguration,
  LoadingStatus,
  useGLActionsRequests,
  useGLAttachmentActionsRequests,
  DashboardListActions,
  DomainDataObjects,
  DomainDataEnum,
  useActionWeightages,
  GeneralLedgerActionResult,
  SanitizedDashboardRow
} from "@jem/components";

import GLDashboardDialog, { GLDashboardDialogRef } from "./GLDashboard.Dialog";
import { glFilterRows } from "./GLDashboard.filterRows";
import { GLTileNames } from "./GLDashboard.Requests";
import { GLDashboardActions } from "./GLDashboard.Actions";
import AttachmentDialog, { AttachmentDialogRef } from "./AttachmentDialog/AttachmentDialog";

// eslint-disable-next-line
const isEqual = require("lodash/isEqual");

export interface GLDashboardProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  attachmentsConfiguration: JemConfiguration["DocumentsApi"];
  items: GLDashboardRow[];
  tilesAreLoading: LoadingStatus;
  dataIsLoading: LoadingStatus;
  uniqueIdForDashboardsColumnGenerator: IDashboardGridProps["idForLocalStorage"];
  columnGenerator: (attachmentDialogRef: AttachmentDialogRef | null) => IDashboardGridProps["columnGenerator"];
  tiles: OrderedMap<string, IIndexedTile>;
  theIndexFromTheColumnsThatIsInitallySortedWith?: number;
  currentTile?: GLTileNames;
  onTileChange?: (tile: GLTileNames) => void;
  buttonCalculator: (rows: GLDashboardRow[]) => IDashboardListActionsState;
  onAction?: (action: GeneralLedgerActionResult, currentTile: string | null) => void;
  domainData: Pick<DomainDataObjects, DomainDataEnum.StatusActions>;
  refetchPaginatedGridData: (
    nextPageIndex: number,
    totalCount: number,
    pageSize: number
  ) => Promise<SanitizedDashboardRow[]>;
  featureFlags?: JemConfiguration["featureFlags"];
  searchType?: boolean;
}

const dashboardFilters: FilterSchema<GLDashboardRow>[] = [
  {
    filterName: "Co Code",
    columnName: "companyCode"
  },
  {
    filterName: "FY",
    columnName: "fiscalYear"
  },
  {
    filterName: "FP",
    columnName: "fiscalPeriod"
  },
  {
    filterName: "Format",
    columnName: "format"
  },
  {
    filterName: "Due Date",
    columnName: "dueDate"
  },
  {
    filterName: "JE Status",
    columnName: "status"
  },
  {
    filterName: "Poster",
    columnName: "poster"
  },
  {
    filterName: "Reviewer",
    columnName: "reviewer"
  },
  {
    filterName: "Backup Posters",
    columnName: "additionalPosters"
  },
  {
    filterName: "Ops Detail",
    columnName: "opsDetailsName"
  },
  {
    filterName: "Checklist Name",
    columnName: "jeChecklistName"
  },
  {
    filterName: "Task Type",
    columnName: "isAdhocOrStd"
  }
];

const GLDashboard = (props: GLDashboardProps): ReturnType<React.FC<GLDashboardProps>> => {
  const location = useLocation();
  const dashboardGridRef = useRef<IDashboardGridRef>(null);
  const dashboardActionsRef = useRef<DashboardListActionsRef>(null);
  const actionManagerRef = useRef<IActionsManagerRef<GLDashboardRow>>(null);
  const dialogRef = useRef<GLDashboardDialogRef>(null);
  const attachmentRef = useRef<AttachmentDialogRef>(null);

  const glActions = useGLActionsRequests(props.configuration, "GLDashboard");
  const attachmentActions = useGLAttachmentActionsRequests(
    props.attachmentsConfiguration,
    `${props.configuration.baseApiUrl}${props.configuration.endpoints.attachmentDelete}`
  );

  const [filteredItems, setFilteredItems] = useState<GLDashboardRow[]>([]);
  const [filterState, setFilterState] = useState<FilterState | null>(null);

  const actionWeightages = useActionWeightages(props.domainData);

  useEffect(
    function onNewGridItemsReloadFilteredItems() {
      if (filterState) {
        const newItems = glFilterRows(filterState, dashboardFilters, props.items);
        setFilteredItems(newItems);
      } else {
        setFilteredItems(props.items);
      }
    },
    [props.items, filterState]
  );

  useEffect(() => {
    if (filterState) {
      location.state = {
        filterState
      };
    }
  }, [filterState]);

  const filterItemsRebuildTiles = (newFilterState?: FilterState) => {
    if (newFilterState) {
      if (newFilterState.currentTile !== props.currentTile && newFilterState.currentTile && props.onTileChange) {
        props.onTileChange(newFilterState.currentTile as GLTileNames);
      }
      if (!isEqual(newFilterState, filterState)) {
        setFilterState(newFilterState);
      }
    }
  };

  const onCancelAction = () => {
    if (dashboardGridRef.current) {
      dashboardGridRef.current.clearSelection();
    }
  };

  const onSubmitAction = (actionResult: GeneralLedgerActionResult) => {
    if (dashboardGridRef.current) {
      dashboardGridRef.current.clearSelection();
    }

    if (props.onAction) {
      props.onAction(actionResult, filterState == null ? null : filterState.currentTile);
    }
  };

  return (
    <>
      <ActionsProvider>
        <ActionsManager<GLDashboardRow, GeneralLedgerActionResult>
          customRef={actionManagerRef}
          onCancel={onCancelAction}
          onSubmit={onSubmitAction}
          attachmentsActionsMap={attachmentActions}
          endpointMap={glActions}
          configuration={props.configuration}
        ></ActionsManager>
      </ActionsProvider>
      <AttachmentDialog customRef={attachmentRef} attachmentsActionsMap={attachmentActions} />
      <GLDashboardDialog customRef={dialogRef} configuration={props.configuration} />
      <div
        className={css`
          position: relative;
          flex-grow: 1;
          width: 100%;
        `}
      >
        <DashboardFilter<GLDashboardRow>
          filters={dashboardFilters}
          singleSelectionFilters={[]}
          searchEnabledFilters={["Co Code"]}
          onFilterChanged={filterItemsRebuildTiles}
          loadingStatus={props.tilesAreLoading}
          tilesAndFilters={{
            gridItems: props.items,
            tiles: props.tiles,
            currentTile: props.currentTile || ""
          }}
          location={location}
        />
      </div>

      <div
        className={css`
          display: inline-flex;
          gap: 16px;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          align-items: stretch;
          width: 100%;
          margin: 16px 0 16px 0;
        `}
      >
        {props.dataIsLoading !== LoadingStatus.Pending && props.items.length == 0 ? (
          <Text
            variant="xLarge"
            style={{
              color: "var(--accent-font-color, black)"
            }}
          >
            No Items to show.
          </Text>
        ) : (
          <>
            <DashboardGrid
              idForLocalStorage={props.uniqueIdForDashboardsColumnGenerator}
              columnGenerator={props.columnGenerator(attachmentRef.current)}
              isSortedIndex={props.theIndexFromTheColumnsThatIsInitallySortedWith}
              items={filteredItems}
              isSortedDescending={true}
              customRef={dashboardGridRef}
              selectionMode={SelectionMode.multiple}
              height={`460px`}
              loadingStatus={props.dataIsLoading}
              onExport={(rows) => {
                exportToExcel({
                  sheetName: "draftJournalEntries",
                  rowsWithHeader: rows,
                  fileName: "JournalEntries.xlsx"
                });
              }}
              onSelectionChanged={(selection: Selection) => {
                if (dashboardActionsRef.current) {
                  const userSelectedItems = selection.getSelection();
                  dashboardActionsRef.current.refreshSelection(userSelectedItems as GLDashboardRow[]);
                }
              }}
              refetchPaginatedGridData={props.refetchPaginatedGridData}
              featureFlags={props.featureFlags}
              searchType={props.searchType}
            >
              <DashboardListActions
                customRef={dashboardActionsRef}
                hide={false}
                buttonCalculator={props.buttonCalculator}
                preventCollapse={true}
                initialState={props.buttonCalculator([])}
                {...GLDashboardActions({
                  actionManagerRef,
                  dashboardGridRef,
                  currentTileName: props.currentTile as unknown as GLTileNames,
                  dialogRef,
                  allItems: filteredItems,
                  actionWeightages
                })}
              ></DashboardListActions>
            </DashboardGrid>
          </>
        )}
      </div>
    </>
  );
};

GLDashboard.displayName = "GLDashboard";
export default GLDashboard;
